.nav {
    background-color: var(--skillsTag);
    padding: 0.6rem 0;
    font-size: 1.5rem;
}
.title {
    height: 5rem;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    margin-top: 2rem;
}
.title div:first-child {
    font-size: 2rem;
    color: var(--sectionTitle);
    padding-bottom: 0.4rem;
}
.bottomborder {
    width: 5rem;
    height: 3px;
    background-color: var(--sectionTitle);
}
.skill_list {
    display: flex;
    flex-direction: column;
}
.skill_list_bg {
    background-color: #f5f5f5;
}
.skill_container {
    background-color: white;
    margin: 1rem;
    padding: 0.5rem;
    box-shadow: 0 0 10px 1px rgba(128, 128, 128, 0.3);
}
.skilltitle {
    padding: 1rem;
    font-size: 1.5rem;
}
.skilldetail {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 1rem;

    /* width: 90%; */
    /* margin: 0 auto; */
}

.tech {
    width: 5rem;
    /* margin-bottom: 1.5rem; */
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tech_title {
    font-size: 1rem;
}
.tech_img {
    width: 100%;
    margin-bottom: 1rem;
    height: 6rem;

    object-fit: contain;
}

@media screen and (max-width: 600px) {
    .skilldetail {
        justify-content: center;
    }
}
@media screen and (max-width: 550px) {
    .tech {
        width: 4rem;
    }
    .tech_title {
        font-size: 0.8rem;
    }
}
@media screen and (max-width: 420px) {
    .tech {
        width: 3rem;
    }
    .tech_title {
        font-size: 0.6rem;
    }
}
