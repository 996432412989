.bg {
    background-color: #f5f5f5;
}

.projectlayout h2 {
    margin: 1rem 2rem;
}
.pd2 {
    padding: 0 2rem;

    /* background-color: red; */
}
.procontent {
    padding: 0 2rem;
}
.techstack {
    display: flex;
    flex-wrap: wrap;
}
.techstack li {
    padding: 0.5rem 0;
    margin: 1rem 2rem;
}

.btnflex {
    display: flex;
    align-items: center;
}
.githubbtn div {
    font-size: 1rem;
    font-weight: 500;
}

.githubbtn {
    background-color: black;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid gray;
    display: inline-block;
    padding: 0.3rem;
}
.titlecontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.titlecontainer h2 {
    font-size: 1.7rem;
}
.titlecontainer div {
    margin-right: 2rem;
    font-size: 1.3rem;
    color: rgb(56, 182, 255);
    cursor: pointer;
    padding: 0.5rem;
}

.title {
    height: 5rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */

    margin: 0 1rem;
    /* padding-top: 3rem; */
}
.myprojectcon {
    padding-top: 3rem;
}
.title div:first-child {
    font-size: 2rem;
    color: var(--sectionTitle);
    padding-bottom: 0.4rem;
}
.bottomborder {
    width: 5rem;
    height: 3px;
    background-color: var(--sectionTitle);
}
.project {
    padding: 1rem;
    border-radius: 20px;
    width: 20rem;
    height: 12rem;
    transition: all 1s;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 2rem;
}
.projecthover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: var(--projectbg);
}

.projecthover p,
span {
    text-align: center;
    color: white;
}
.description {
    margin: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.projecthead {
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
}
.seebtn {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    text-align: center;
    width: 10rem;
    cursor: pointer;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    border: 1px solid var(--projectbtn);
}
.seebtn:hover {
    background-color: var(--projectbtn);
}
.projectitems {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
}
.projectimg {
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1195px) {
    .project {
        width: 17rem;
        height: 10rem;
    }
}
@media screen and (max-width: 620px) {
    .projecthead {
        font-size: 1rem;
    }
    .project {
        width: 15rem;
        height: 9rem;
    }
}
@media screen and (max-width: 522px) {
    .project {
        width: 100%;
        height: 15rem;
    }
    .procontent {
        padding: 0 1rem;
    }
    .titlecontainer h2 {
        font-size: 1.2rem;
    }
    .titlecontainer div {
        font-size: 1rem;
    }
}
@media screen and (max-width: 400px) {
    .project {
        width: 100%;
        height: 12rem;
    }
}
